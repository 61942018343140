<template>
    <v-container fluid :class="affichagePortable ? 'pa-0' : 'pt-4'">
		<v-row justify="center" class="ma-0">
			<v-col cols="12" sm="4" class="pa-0">
                <v-toolbar dark flat dense color="deep-purple lighten-1">
                    <v-toolbar-title>Utilisateurs connectés</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-chip class="ma-2" color="deep-purple lighten-3">{{ liste_utilisateurs_triees.length }}</v-chip>
                </v-toolbar>
                <v-list dense v-if="liste_utilisateurs_triees.length > 0" class="pb-0">
                    <template v-for="(utilisateur, index) in liste_utilisateurs_triees">
                            <v-list-item :key="utilisateur.id" ripple>
                                <v-list-item-avatar size="30" color="deep-purple lighten-3">
                                    <img v-if="utilisateur.photo" :src="getPhoto(utilisateur.photo)">
                                    <span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(utilisateur.prenom)+premiereLettreMaj(utilisateur.nom)}}</span>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ utilisateur.nom + ' ' +  utilisateur.prenom}}</v-list-item-title>
                                    <v-list-item-subtitle v-if="utilisateur.membres_centre.length > 0">
                                        <span v-for="(membre, index) in utilisateur.membres_centre" :key="membre.id">
                                            <span v-if="index > 0"> / </span><span>{{membre.centre.nom}}</span>
                                        </span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider v-if="index + 1 < liste_utilisateurs_triees.length" :key="'divider'+index"></v-divider>
                        </template>
                </v-list>
                <div v-else class="pa-3 text-center">
                    <span class="text-subtitle-1 primary--text font-weight-light">(aucun utilisteur connecté)</span>
                </div>
            </v-col>
		</v-row>
	</v-container>
</template>

<script>
    import axios from 'axios'
    import { required } from 'vuelidate/lib/validators'
    export default {
        created() {
            this.$store.commit('majTitrePage', 'Online')
        
        },
        mounted() {
            
        },
        data() {
			return {
               
                
            }
		},
		computed: {
            affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
            },
            liste_utilisateurs_triees() {
                //  trie par ordre alphabétique de la liste des connectés
                
                const liste_complete = this.$store.getters.usersOnline
                
                return liste_complete.sort((a,b) => {
					if(a.nom.toLowerCase() < b.nom.toLowerCase()) {
						return -1
					}
					else if(a.nom.toLowerCase() > b.nom.toLowerCase()) {
						return 1
					}

					//	ici on a le même nom donc on tri par prénom
					if(a.prenom.toLowerCase() < b.prenom.toLowerCase()) {
						return -1
					}
					else if(a.prenom.toLowerCase() > b.prenom.toLowerCase()) {
						return 1
					}
					return 0
				})

            }
            
		},
		methods: {
            getPhoto(chemin){
				return "/storage/"+chemin;
            },
            premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
			},
           

        },
        
  	}
</script>

<style>


</style>

